import { useStyles } from "app/study/CreateEditStudyFormStyles";
import {
  CardContent,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  Card,
  TextField,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { StudyContactRole } from "app/shared/constants";
import { useFormikContext } from "formik";

const StudyContactsTab = () => {
  const { values, setFieldValue, errors, handleBlur, touched } =
    useFormikContext();

  const { t } = useTranslation();
  const classes = useStyles();
  const { studyContacts, recruitmentEmail, recruitmentPhone } = values;

  const displayACard = (contact, contactIndex) => {
    return (
      <Grid
        container
        direction="column"
        key={contact.staffId}
        className={classes.contentNoWrap}
      >
        <Card key={contact.staffId} className={classes.customContactGridWidth}>
          <CardContent>
            <Grid item>
              <TextField
                fullWidth
                name="name"
                variant="outlined"
                margin="dense"
                label={t(`formLabel.name`)}
                value={contact.firstName + " " + contact.lastName}
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                name="role"
                variant="outlined"
                margin="dense"
                label={t(`formLabel.role`)}
                value={contact.role}
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                name="sunetid"
                variant="outlined"
                margin="dense"
                label={t(`formLabel.sunetid`)}
                value={contact.sunetid}
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                name="email"
                variant="outlined"
                margin="dense"
                label={t(`formLabel.email`)}
                value={contact.email}
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                name={`${contact.staffId}_${contact.role}_note`}
                label={t(`formLabel.note`)}
                variant="outlined"
                margin="dense"
                onChange={(event) => {
                  setFieldValue(
                    `studyContacts[${contactIndex}].note`,
                    event.target.value
                  );
                }}
                value={contact.note}
              />
            </Grid>
            {contact.role !== StudyContactRole.PI && (
              <Grid item>
                <FormControl error={errors.studyContacts ? true : false}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={contact.leadCrc}
                        color="primary"
                        onChange={(evt, newValue) => {
                          setFieldValue(
                            `studyContacts[${contactIndex}].leadCrc`,
                            newValue
                          );
                        }}
                      />
                    }
                    label={t("formLabel.leadCRC")}
                    name={`${contact.staffId}_${contact.role}_leadCRC`}
                  />
                  {errors.studyContacts && (
                    <FormHelperText focused={true}>
                      {t("formValidation.oneMainCRC")}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <Grid container spacing={2} className={classes.contentNoWrap}>
      <Grid container direction="column" item xs>
        <Grid item className={classes.customContactGridWidth}>
          <TextField
            fullWidth
            name="recruitmentEmail"
            label={t(`formLabel.recruitmentEmail`)}
            value={recruitmentEmail}
            variant="outlined"
            margin="dense"
            onBlur={handleBlur}
            onChange={(e) => {
              setFieldValue("recruitmentEmail", e.target.value);
            }}
            error={
              touched.recruitmentEmail && errors.recruitmentEmail ? true : false
            }
            helperText={
              touched.recruitmentEmail &&
              errors.recruitmentEmail &&
              t("formValidation.invalidEmail")
            }
          />
        </Grid>
        <Grid item className={classes.customContactGridWidth}>
          <TextField
            fullWidth
            name="recruitmentPhone"
            label={t(`formLabel.recruitmentPhone`)}
            value={recruitmentPhone}
            variant="outlined"
            margin="dense"
            onBlur={handleBlur}
            onChange={(e) => {
              setFieldValue("recruitmentPhone", e.target.value);
            }}
            error={
              touched.recruitmentPhone && errors.recruitmentPhone ? true : false
            }
            helperText={
              touched.recruitmentPhone &&
              errors.recruitmentPhone &&
              t("formValidation.invalidPhoneNumber")
            }
          />
        </Grid>
      </Grid>

      {/* PI */}
      {studyContacts &&
        studyContacts.map((contact, index) => {
          return (
            contact.role === StudyContactRole.PI && (
              <Grid item xs>
                {displayACard(contact, index)}
              </Grid>
            )
          );
        })}

      {/* PRIMARY_CRC */}
      {studyContacts &&
        studyContacts.map((contact, index) => {
          return (
            contact.role !== StudyContactRole.PI && (
              <Grid item xs>
                {displayACard(contact, index)}
              </Grid>
            )
          );
        })}
    </Grid>
  );
};

export default StudyContactsTab;
