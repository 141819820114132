import { useStyles } from "app/study/CreateEditStudyFormStyles";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  OutlinedInput,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";
import {
  StudyEntities,
  StudyChannelOptions,
  RecruitmentPreferences,
} from "app/shared/constants";
import PEChipContainer from "app/shared/UI/PEChipContainer";
import PEChip from "app/shared/UI/PEChip";
import { getPrefByName } from "app/shared/utils";
import { useFormikContext } from "formik";

const StudyPreferencesTab = () => {
  const {
    values,
    setFieldTouched,
    setFieldValue,
    errors,
    touched,
    handleBlur,
  } = useFormikContext();
  const { t } = useTranslation();
  const classes = useStyles();

  // checks if there are any errors in the recruitment preferences(preference tab fields) for a specific preference index.
  const hasRecruitmentPreferencesErrors = (prefIndex) =>
    Object.keys(errors).length > 0 &&
    Object.keys(touched).length > 0 &&
    touched?.recruitmentPreferences &&
    touched?.recruitmentPreferences[prefIndex] &&
    errors?.recruitmentPreferences &&
    errors?.recruitmentPreferences[prefIndex]
      ? true
      : false;

  return (
    <Grid container justify="space-between" spacing={2}>
      <Grid item xs={6}>
        <FormControl
          fullWidth
          variant="outlined"
          error={hasRecruitmentPreferencesErrors(0)}
        >
          <InputLabel htmlFor="recruitmentPreferences[0].value" shrink={true}>
            {t("recruitmentPreference." + RecruitmentPreferences[0])}
          </InputLabel>
          <Select
            multiple
            required
            input={
              <OutlinedInput
                id="recruitmentPreferences[0].value"
                name="recruitmentPreferences[0].value"
                label={t("recruitmentPreference." + RecruitmentPreferences[0])}
                margin="dense"
                notched
                onBlur={handleBlur}
                onChange={(event, value) => {
                  setFieldTouched("recruitmentPreferences[0].value", true);
                  setFieldValue(
                    "recruitmentPreferences[0].value",
                    event.target.value.toString()
                  );
                }}
              />
            }
            value={
              getPrefByName(values, RecruitmentPreferences[0]) &&
              getPrefByName(values, RecruitmentPreferences[0]).value
                ? getPrefByName(values, RecruitmentPreferences[0]).value.split(
                    ","
                  )
                : []
            }
            renderValue={(selected) => (
              <PEChipContainer>
                {selected.map((value) => (
                  <PEChip
                    key={value}
                    label={t("recruitmentPreference." + value)}
                  />
                ))}
              </PEChipContainer>
            )}
          >
            {Object.keys(StudyChannelOptions).map((item) => (
              <MenuItem key={item} value={item}>
                {t("recruitmentPreference." + item)}
              </MenuItem>
            ))}
          </Select>
          {hasRecruitmentPreferencesErrors(0) && (
            <FormHelperText focused={true}>
              {t("formValidation.requiredField")}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl
          fullWidth
          variant="outlined"
          error={hasRecruitmentPreferencesErrors(1)}
        >
          <InputLabel htmlFor="recruitmentPreferences[1].value" shrink={true}>
            {t("recruitmentPreference." + RecruitmentPreferences[1])}
          </InputLabel>
          <Select
            multiple
            required
            input={
              <OutlinedInput
                id="recruitmentPreferences[1].value"
                name="recruitmentPreferences[1].value"
                label={t("recruitmentPreference." + RecruitmentPreferences[1])}
                margin="dense"
                size="small"
                notched
                onBlur={handleBlur}
                onChange={(event, value) => {
                  setFieldTouched("recruitmentPreferences[1].value", true);
                  setFieldValue(
                    "recruitmentPreferences[1].value",
                    event.target.value.toString()
                  );
                }}
              />
            }
            value={
              getPrefByName(values, RecruitmentPreferences[1]) &&
              getPrefByName(values, RecruitmentPreferences[1]).value
                ? getPrefByName(values, RecruitmentPreferences[1]).value.split(
                    ","
                  )
                : []
            }
            renderValue={(selected) => (
              <PEChipContainer>
                {selected.map((value) => (
                  <PEChip key={value} label={value} />
                ))}
              </PEChipContainer>
            )}
          >
            {StudyEntities.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          {hasRecruitmentPreferencesErrors(1) && (
            <FormHelperText focused={true}>
              {t("formValidation.requiredField")}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth variant="outlined">
          <TextField
            fullWidth
            id="recruitmentPreferences[2].value"
            name="recruitmentPreferences[2].value"
            label={t("recruitmentPreference." + RecruitmentPreferences[2])}
            variant="outlined"
            margin="dense"
            onBlur={handleBlur}
            onChange={(event, value) => {
              setFieldTouched("recruitmentPreferences[2].value", true);
              setFieldValue(
                "recruitmentPreferences[2].value",
                event.target.value.toString()
              );
            }}
            value={
              getPrefByName(values, RecruitmentPreferences[2])
                ? getPrefByName(values, RecruitmentPreferences[2]).value
                : 0
            }
            className={classes.space}
            required={true}
            error={hasRecruitmentPreferencesErrors(2)}
            helperText={
              hasRecruitmentPreferencesErrors(2)
                ? t("formValidation.requiredField")
                : null
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth variant="outlined">
          <TextField
            fullWidth
            id="recruitmentPreferences[3].value"
            name="recruitmentPreferences[3].value"
            label={t("recruitmentPreference." + RecruitmentPreferences[3])}
            variant="outlined"
            margin="dense"
            onBlur={handleBlur}
            onChange={(event, value) => {
              setFieldTouched("recruitmentPreferences[3].value", true);
              setFieldValue(
                "recruitmentPreferences[3].value",
                event.target.value.toString()
              );
            }}
            value={
              getPrefByName(values, RecruitmentPreferences[3])
                ? getPrefByName(values, RecruitmentPreferences[3]).value
                : 0
            }
            className={classes.space}
            required={true}
            error={hasRecruitmentPreferencesErrors(3)}
            helperText={
              hasRecruitmentPreferencesErrors(3)
                ? t("formValidation.requiredField")
                : null
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <FormControlLabel
            fullWidthLabel
            control={
              <Checkbox
                checked={
                  getPrefByName(values, RecruitmentPreferences[4]) &&
                  getPrefByName(values, RecruitmentPreferences[4]).value ===
                    "true"
                    ? true
                    : false
                }
                color="primary"
                onChange={(evt, newValue) => {
                  setFieldTouched("recruitmentPreferences[4].value", true);
                  setFieldValue(
                    "recruitmentPreferences[4].value",
                    newValue.toString()
                  );
                }}
              />
            }
            name="prefIncludeUndecided"
            label={t("recruitmentPreference." + RecruitmentPreferences[4])}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth variant="outlined">
          <TextField
            fullWidth
            id="recruitmentPreferences[5].value"
            name="recruitmentPreferences[5].value"
            label={t("recruitmentPreference." + RecruitmentPreferences[5])}
            variant="outlined"
            margin="dense"
            onBlur={handleBlur}
            onChange={(evt) => {
              const value = evt.target.value;

              // Update the field value regardless of validation outcome
              setFieldTouched("recruitmentPreferences[5].value", true);
              setFieldValue(
                "recruitmentPreferences[5].value",
                value.toString()
              );
            }}
            value={
              getPrefByName(values, RecruitmentPreferences[5])
                ? getPrefByName(values, RecruitmentPreferences[5]).value
                : ""
            }
            className={classes.space}
            error={hasRecruitmentPreferencesErrors(5)}
            helperText={
              hasRecruitmentPreferencesErrors(5)
                ? errors?.recruitmentPreferences[5].value ===
                  t("formValidation.preferenceOnboardingCapacity")
                  ? t("formValidation.preferenceOnboardingCapacity")
                  : t("formValidation.requiredField")
                : null
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl
          fullWidth
          variant="outlined"
          className={classes.areaZipCodes}
        >
          <TextField
            fullWidth
            multiline
            rows={5}
            name="prefIncludeZipCode"
            label={t(`recruitmentPreference.${RecruitmentPreferences[6]}`)}
            variant="outlined"
            margin="dense"
            onChange={(e) => {
              setFieldTouched("recruitmentPreferences[6].value", true);
              setFieldValue(
                "recruitmentPreferences[6].value",
                e.target.value.toString()
              );
            }}
            value={
              getPrefByName(values, RecruitmentPreferences[6])
                ? getPrefByName(values, RecruitmentPreferences[6]).value
                : ""
            }
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default StudyPreferencesTab;
