import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Paper,
  Typography,
  Divider,
  TextField,
  MenuItem,
  Button,
  InputLabel,
  Select,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { useStyles } from "app/prospect/EditProspectPageStyles";
import PEMail from "app/shared/UI/PEMail";
import AntSwitch from "app/shared/UI/AntSwitch";
import { useTranslation } from "react-i18next";
import { OptOutReason } from "app/shared/constants";
import {
  getProspectByMrn,
  editProspectById,
} from "app/services/prospectServices";
import { useAlerts } from "common";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { differenceObject } from "app/shared/utils";
import ConfirmationDialog from "app/shared/UI/PEConfirmation";
import ProspectDetailsOutreachHistory from "app/prospect/ProspectDetailsOutreachHistory";
import ProspectDetailsCohortHistory from "app/prospect/ProspectDetailsCohortHistory";

const EditProspectPageContent = (props) => {
  const { state = { prospect: { emails: [], phones: [], addresses: [] } } } =
    props.location;
  const { prospectId } = props.match.params;
  const { setAlert } = useAlerts();
  const classes = useStyles();
  const { t } = useTranslation();
  const { prospect } = state;
  const history = useHistory();

  const [prospectData, setProspectData] = useState(prospect);
  const { emails = [], phones = [], addresses = [] } = prospectData;

  const [optedStatus, setOptedStatus] = useState(
    prospectData.optOut ? true : false
  );

  useEffect(() => {
    getProspectByMrn(prospectId, setProspectData, setAlert, setOptedStatus);
  }, [prospectId, setAlert]);

  const [openASConfirmation, setOpenASConfirmation] = useState(false);

  const formValidationSchema = Yup.object().shape({
    optOut: Yup.object()
      .shape({
        optOutReason: Yup.string()
          .required(t("formValidation.optOutReasonRequired"))
          .nullable(),
        optOutDate: Yup.date()
          .typeError(t("formValidation.invalidDateFormat"))
          .required(t("formValidation.optOutDateRequired"))
          .nullable(),
        optOutComment: Yup.string().required(
          t("formValidation.optOutCommentRequired")
        ),
      })
      .nullable()
      .default(undefined),
    concern: Yup.object()
      .shape({
        concernDate: Yup.date()
          .typeError(t("formValidation.invalidDateFormat"))
          .required(t("formValidation.concernDateReq"))
          .nullable(),
        concernDescription: Yup.string()
          .required(t("formValidation.concernReq"))
          .nullable(),
      })
      .nullable()
      .default(undefined),
  });
  const diff1 = (values) => differenceObject(values, prospectData);

  const handleClickCancel = (dirty) => {
    if (dirty) {
      setOpenASConfirmation(true);
    } else {
      history.push("/prospects/lookup");
    }
  };
  const closeCancelASConfirmation = (cancelChoice) => {
    setOpenASConfirmation(false);
    if (cancelChoice) {
      history.push("/prospects/lookup");
    }
  };

  const getValueSeparator = (value, type) => {
    if (type === "email") {
      return value.map((row, idx) => (
        <Typography key={idx} variant="subtitle1">
          <PEMail emailAddress={row}>{(idx ? ", " : "") + row}</PEMail>
        </Typography>
      ));
    }
    return <Typography variant="subtitle1">{value.join(", ")}</Typography>;
  };

  return (
    <div className={classes.root}>
      <ConfirmationDialog
        open={openASConfirmation}
        onClose={closeCancelASConfirmation}
        message={t(`OutreachDetails.confirmationMessageCancel`)}
        okLabel={t("ConductOutreachPage.yesLabel")}
        cancelLabel={t("ConductOutreachPage.noLabel")}
      />
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography>{t("LookupProspect.prospectDetails")}</Typography>
            <Grid container item xs={12} className={classes.customPadding}>
              <Grid item xs={3}>
                {t("LookupProspect.tableColumnMRN")}:
              </Grid>
              <Grid item xs={9}>
                <Typography variant="subtitle1">{prospectData.mrn}</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.customPadding}>
              <Grid item xs={3}>
                {t("LookupProspect.tableColumnFirstName")}:
              </Grid>
              <Grid item xs={9}>
                <Typography variant="subtitle1">
                  {prospectData.firstName}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.customPadding}>
              <Grid item xs={3}>
                {t("LookupProspect.tableColumnLastName")}:
              </Grid>
              <Grid item xs={9}>
                <Typography variant="subtitle1">
                  {prospectData.lastName}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.customPadding}>
              <Grid item xs={3}>
                {t("LookupProspect.tableColumnEmail")}:
              </Grid>
              <Grid container item xs={9}>
                {getValueSeparator(emails, "email")}
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.customPadding}>
              <Grid item xs={3}>
                {t("LookupProspect.tableColumnPhone")}:
              </Grid>
              <Grid container item xs={9}>
                {getValueSeparator(phones)}
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.customPadding}>
              <Grid item xs={3}>
                {t("LookupProspect.tableColumnDOB")}:
              </Grid>
              <Grid item xs={9}>
                <Typography variant="subtitle1">{prospectData.dob}</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.customPadding}>
              <Grid item xs={3}>
                {t("LookupProspect.tableColumnAddress")}:
              </Grid>
              <Grid item xs={9}>
                {addresses.map((row, idx) => (
                  <Typography key={idx} variant="subtitle1">
                    {row}
                    <Divider orientation="horizontal" />
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Formik
            initialValues={prospectData}
            enableReinitialize={true}
            validationSchema={formValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              editProspectById(
                prospectId,
                diff1(values),
                setAlert,
                setProspectData,
                history
              );
            }}
          >
            {({
              values,
              isValid,
              dirty,
              isSubmitting,
              handleBlur,
              setFieldValue,
              setValues,
              errors,
              touched,
            }) => {
              return (
                <Form>
                  <Paper className={classes.paper}>
                    <Typography>{t("LookupProspect.editProspect")}</Typography>

                    <Grid
                      container
                      item
                      xs={12}
                      className={classes.customPadding}
                    >
                      <Grid item xs={3}>
                        {t("LookupProspect.optedStatus")}
                      </Grid>
                      <Typography component="div">
                        <Grid
                          component="label"
                          container
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>
                            <Typography variant="subtitle1">In</Typography>
                          </Grid>
                          <Grid item>
                            <AntSwitch
                              name="optedStatus"
                              checked={optedStatus}
                              onChange={(evt) => {
                                setOptedStatus(evt.target.checked);
                                if (evt.target.checked) {
                                  setFieldValue(
                                    `optOut[optOutDate]`,
                                    new Date().toISOString()
                                  );
                                } else {
                                  setFieldValue(`optOut`, null);
                                }
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle1">Out</Typography>
                          </Grid>
                        </Grid>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.customTopPadding}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        error={
                          errors.optOut &&
                          touched.optOutReason &&
                          Boolean(errors.optOut.optOutReason)
                        }
                        className={classes.selectMarginDense}
                      >
                        <InputLabel
                          variant="outlined"
                          required={optedStatus}
                          disabled={!optedStatus}
                          id="optOutReason"
                          shrink
                        >
                          {t("LookupProspect.optOutReason")}
                        </InputLabel>
                        <Select
                          label={t("LookupProspect.optOutReason")}
                          labelId="optOutReason"
                          displayEmpty
                          onBlur={handleBlur}
                          value={
                            values.optOut && values.optOut.optOutReason
                              ? values.optOut.optOutReason
                              : ""
                          }
                          disabled={!optedStatus}
                          onChange={(evt) => {
                            setFieldValue(
                              `optOut[optOutReason]`,
                              evt.target.value
                            );
                          }}
                          fullWidth
                          name="optOutReason"
                          notched
                        >
                          <MenuItem value={""}>{t("formLabel.none")}</MenuItem>
                          {OptOutReason.map((item) => (
                            <MenuItem key={item} value={item}>
                              {t("LookupProspect." + item)}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {errors &&
                            touched.optOutReason &&
                            errors.optOut?.optOutReason}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid
                      container
                      className={classes.customTopPadding}
                      justify="space-between"
                    >
                      <Grid item xs={7}>
                        <TextField
                          name="optOutComment"
                          label={t("LookupProspect.optOutComment")}
                          variant="outlined"
                          multiline={true}
                          onBlur={handleBlur}
                          disabled={!optedStatus}
                          fullWidth
                          size="small"
                          value={
                            values.optOut && values.optOut.optOutComment
                              ? values.optOut.optOutComment
                              : ""
                          }
                          onChange={(evt) => {
                            setFieldValue(
                              `optOut[optOutComment]`,
                              evt.target.value
                            );
                          }}
                          error={
                            errors.optOut &&
                            touched.optOutComment &&
                            Boolean(errors.optOut.optOutComment)
                          }
                          helperText={
                            errors &&
                            touched.optOutComment &&
                            errors.optOut?.optOutComment
                          }
                        />
                      </Grid>
                      <Grid item xs={4} className={classes.datePicker}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            label={t("LookupProspect.optOutDate")}
                            format="MM/dd/yyyy"
                            onBlur={handleBlur}
                            margin="dense"
                            disabled={!optedStatus}
                            value={values.optOut && values.optOut.optOutDate}
                            error={
                              errors.optOut && Boolean(errors.optOut.optOutDate)
                            }
                            helperText={errors && errors.optOut?.optOutDate}
                            onChange={(date, value) => {
                              const checkDate = Date.parse(date);

                              if (!isNaN(checkDate)) {
                                setFieldValue(
                                  `optOut[optOutDate]`,
                                  date.toISOString()
                                );
                              } else {
                                setFieldValue(`optOut[optOutDate]`, value);
                              }
                            }}
                            id="optOutDate"
                            name="optOutDate"
                            fullWidth
                            inputProps={{ autoComplete: "off" }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      className={classes.customTopPadding}
                      justify="space-between"
                    >
                      <Grid item xs={7}>
                        <TextField
                          name="concern"
                          label={t("LookupProspect.concern")}
                          variant="outlined"
                          multiline={true}
                          onBlur={handleBlur}
                          fullWidth
                          size="small"
                          value={
                            values.concern && values.concern.concernDescription
                          }
                          error={
                            errors.concern &&
                            touched.concernDescription &&
                            Boolean(errors.concern.concernDescription)
                          }
                          helperText={
                            errors &&
                            touched.concernDescription &&
                            errors.concern?.concernDescription
                          }
                          onChange={(evt) => {
                            const newData = evt.target.value
                              ? {
                                  ...values.concern,
                                  concernDescription: evt.target.value,
                                  concernDate: format(new Date(), "yyyy-MM-dd"),
                                }
                              : null;

                            setValues((prevValues) => ({
                              ...prevValues,
                              // we use the name to tell Formik which key of `values` to update
                              concern: newData,
                            }));
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} className={classes.datePicker}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            label={t("LookupProspect.concernDate")}
                            format="MM/dd/yyyy"
                            onBlur={handleBlur}
                            margin="dense"
                            value={
                              values.concern && values.concern.concernDate
                                ? values.concern.concernDate
                                : null
                            }
                            disabled={!values.concern}
                            error={
                              errors.concern &&
                              Boolean(errors.concern.concernDate)
                            }
                            helperText={errors && errors.concern?.concernDate}
                            onChange={(date, value) => {
                              const checkDate = Date.parse(date);

                              if (!isNaN(checkDate)) {
                                setFieldValue(
                                  `concern[concernDate]`,
                                  format(new Date(date), "yyyy-MM-dd")
                                );
                              } else {
                                setFieldValue(`concern[concernDate]`, value);
                              }
                            }}
                            id="concernDate"
                            name="concernDate"
                            fullWidth
                            inputProps={{ autoComplete: "off" }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.customTopPadding}>
                      <TextField
                        name="notes"
                        label={t("LookupProspect.notes")}
                        variant="outlined"
                        multiline={true}
                        onBlur={handleBlur}
                        fullWidth
                        size="small"
                        value={values.note}
                        onChange={(evt) => {
                          setFieldValue("note", evt.target.value);
                        }}
                      />
                    </Grid>
                    <Grid
                      container
                      justify="flex-end"
                      className={classes.customPadding}
                    >
                      <Grid container justify="flex-end" item xs={2}>
                        <Button
                          variant="outlined"
                          onClick={() => handleClickCancel(dirty)}
                        >
                          {t("formLabel.buttonCancel")}
                        </Button>
                      </Grid>
                      <Grid container justify="flex-end" item xs={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={
                            !isValid ||
                            !dirty ||
                            isSubmitting ||
                            !Object.keys(diff1(values)).length > 0
                          }
                        >
                          {t("formLabel.submitButtonLabel")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Form>
              );
            }}
          </Formik>
        </Grid>
        <Grid item xs={12} className={classes.gridContainer}>
          <Typography variant="h6">
            {t(`ProspectDetailsCohortHistory.cohortHistoryLabel`)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ProspectDetailsCohortHistory mrn={prospectId} />
        </Grid>
        <Grid item xs={12} className={classes.gridContainer}>
          <Typography variant="h6">
            {t(`ProspectDetailsOutreachHistory.outreachHistoryLabel`)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ProspectDetailsOutreachHistory mrn={prospectId} />
        </Grid>
      </Grid>
    </div>
  );
};

export default EditProspectPageContent;
