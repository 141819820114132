import { useTranslation } from "react-i18next";
import { MenuItem, Grid, TextField } from "@material-ui/core";
import { useStudies } from "app/services/studiesService";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  defaultFUDateFrom,
  defaultDateTo,
  SummaryAccrual,
  MultiSite,
  RecruitmentStatusOptions,
} from "app/shared/constants";
import isEmpty from "lodash/isEmpty";
import { Autocomplete } from "app/UI/Autocomplete/Autocomplete";
import { useFormikContext } from "formik";

const StudyDetailsTab = () => {
  const {
    values,
    setFieldTouched,
    setFieldValue,
    errors,
    touched,
    handleBlur,
  } = useFormikContext();

  const { t } = useTranslation();
  const { therapeuticAreas, fundingSources, allHonestBrokers } = useStudies();

  return !isEmpty(values) ? (
    <Grid container justify="space-between" spacing={2}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="protocolDepartment"
          label={t(`formLabel.department`)}
          variant="outlined"
          value={values.protocolDeptName || ""}
          disabled
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="division"
          label={t(`formLabel.division`)}
          value={values.division}
          variant="outlined"
          onBlur={handleBlur}
          onChange={(e) => {
            setFieldValue("division", e.target.value);
          }}
          size="small"
          error={touched.division && Boolean(errors.division)}
          helperText={
            touched.division &&
            errors.division &&
            t("formValidation.requiredField")
          }
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          select
          label={t(`formLabel.therapeuticArea`)}
          variant="outlined"
          size="small"
          name="therapeuticArea"
          value={values.therapeuticArea ? values.therapeuticArea?.name : ""}
          onChange={(e) => {
            const matchedObj = therapeuticAreas.find(
              (item) => item.name === e.target.value
            );
            setFieldValue("therapeuticArea", matchedObj);
          }}
        >
          <MenuItem value={0}>{t("formLabel.none")}</MenuItem>
          {therapeuticAreas &&
            therapeuticAreas.map((item) => (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          id="honestBroker"
          key="honest_Broker"
          size="small"
          options={allHonestBrokers || []}
          getOptionLabel={(option) => (option ? option.displayName : "")}
          getOptionDisabled={(option) => !option.currentHonestBroker}
          getOptionSelected={(option, value) => option.id === value?.id}
          label={t("formLabel.honestBroker")}
          variant="outlined"
          margin="dense"
          value={values.honestBroker}
          onBlur={handleBlur}
          onChange={async (e, newValue) => {
            (await setFieldTouched("honestBrokerRecruitmentStatus", true)) &&
              setFieldValue("honestBroker", newValue ? newValue : null);

            if (
              newValue &&
              newValue?.id &&
              values.honestBrokerRecruitmentStatus ===
                RecruitmentStatusOptions[6]
            ) {
              setFieldTouched("honestBrokerRecruitmentStatus", true);
              setFieldValue(
                "honestBrokerRecruitmentStatus",
                RecruitmentStatusOptions[0]
              );
            }
          }}
          clientSide={true}
          error={touched.honestBroker && errors.honestBroker ? true : false}
          helperText={
            touched.honestBroker && errors.honestBroker
              ? t("formValidation.requiredField")
              : null
          }
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          fullWidth
          name="cancerCenter"
          label={t(`formLabel.cancerCenterLabel`)}
          variant="outlined"
          value={
            values.protocolCancerCenter !== undefined
              ? values.protocolCancerCenter
                ? "Yes"
                : "No"
              : ""
          }
          disabled
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="studySource"
          label={t(`formLabel.studySourceLabel`)}
          variant="outlined"
          value={values.source || ""}
          disabled
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="piInitiated"
          label={t(`formLabel.piInitiated`)}
          value={values.protocolInvestigatorInitiated || ""}
          variant="outlined"
          disabled
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          select
          label={t(`formLabel.fundingSource`)}
          variant="outlined"
          size="small"
          name="fundingSource"
          value={values.fundingSource ? values.fundingSource?.name : ""}
          onChange={(e) => {
            const matchedObj = fundingSources.find(
              (item) => item.name === e.target.value
            );
            setFieldValue("fundingSource", matchedObj);
          }}
        >
          <MenuItem value={0}>{t("formLabel.none")}</MenuItem>
          {fundingSources &&
            fundingSources.map((item) => (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            name="recruitmentStartDate"
            disableToolbar
            autoOk
            fullWidth
            variant="inline"
            inputVariant="outlined"
            margin="dense"
            format="MM/dd/yyyy"
            label={t("formLabel.recruitmentStartDate")}
            minDate={defaultFUDateFrom}
            maxDate={defaultDateTo}
            error={
              touched.recruitmentStartDate && !!errors.recruitmentStartDate
            }
            helperText={
              touched.recruitmentStartDate && errors.recruitmentStartDate
                ? errors.recruitmentStartDate
                : null
            }
            onBlur={handleBlur}
            onChange={(date) => {
              setFieldValue(
                "recruitmentStartDate",
                !date ? new Date() : new Date(date)
              );
            }}
            rules={{ required: true }}
            value={values.recruitmentStartDate}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            name="recruitmentEndDate"
            disableToolbar
            autoOk
            fullWidth
            variant="inline"
            inputVariant="outlined"
            margin="dense"
            format="MM/dd/yyyy"
            label={t("formLabel.recruitmentEndDate")}
            minDate={defaultFUDateFrom}
            maxDate={defaultDateTo}
            error={touched.recruitmentEndDate && !!errors.recruitmentEndDate}
            helperText={
              touched.recruitmentEndDate && errors.recruitmentEndDate
                ? errors.recruitmentEndDate
                : null
            }
            onBlur={handleBlur}
            onChange={(date) => {
              setFieldValue(
                "recruitmentEndDate",
                !date ? new Date() : new Date(date)
              );
            }}
            rules={{ required: true }}
            value={values.recruitmentEndDate}
          />
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item xs={6}>
        <TextField
          fullWidth
          name="protocolSummaryAccrual"
          label={t(`formLabel.summaryAccrual`)}
          value={
            values.protocolSummaryAccrual
              ? SummaryAccrual.SummaryAccrualTrue
              : SummaryAccrual.SummaryAccrualFalse || ""
          }
          variant="outlined"
          disabled
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="protocolMultisiteTrial"
          label={t(`formLabel.multiSite`)}
          value={
            values.protocolMultisiteTrial
              ? MultiSite.MultiSiteTrue
              : MultiSite.MultiSiteFalse || ""
          }
          variant="outlined"
          disabled
          size="small"
        />
      </Grid>
    </Grid>
  ) : null;
};

export default StudyDetailsTab;
