import { useFilters } from "app/services/filterService";
import {
  CancerNonCancer,
  GET_ALL_HBUSERS_URL,
  GET_BENCHMARKS_URL,
  GET_FUNDINGSOURCES_URL_BASE,
  GET_STUDIES_URL_BASE,
  GET_THERAPEUTICAREAS_URL,
  MultiSite,
  StudyDefaults,
  SummaryAccrual,
  URLs,
  STORAGE_NAMES,
  STORAGE_TYPE,
} from "app/shared/constants";
import {
  isStudiesLocation,
  isValidUser,
  removeEmptyParams,
  setDefaultHB,
} from "app/shared/utils";
import axios from "axios";
import { useAlerts, useUsers } from "common";
import { formatISO } from "date-fns";
import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useBrowserStorage } from "app/services/StorageService/StorageHelper";

const StudiesContext = createContext();

export const StudiesProvider = ({ children }) => {
  const { currentUser } = useUsers();
  const { setAlert } = useAlerts();
  const [studies, setStudies] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [therapeuticAreas, setTherapeuticAreas] = useState([]);
  const [fundingSources, setFundingSources] = useState([]);
  const [benchmarks, setBenchmarks] = useState({});
  const [allHonestBrokers, setAllHonestBrokers] = useState([]);

  const { getItem, saveItem } = useBrowserStorage(STORAGE_TYPE.SESSION);
  const location = useLocation();
  const {
    filterData: { honestBrokers },
    initialLoad,
  } = useFilters();

  const recalcTotalsInCategorySpec = (
    categorySpec,
    targetValues,
    protocolTargetAccrual
  ) => {
    // Calculate the delta for each value based on actual and target values
    categorySpec.values.forEach((v, i) => {
      if (protocolTargetAccrual === 0) v["delta"] = 0;
      else v["delta"] = v["actual"] - targetValues[i]; // Calculate delta as the difference between actual and target
    });

    // Create a sorted copy of the delta values for ranking
    const valuesSorted = [...categorySpec.values];
    let valueDeltasSorted = valuesSorted
      .map((v) => v.delta) // Extract delta values
      .sort((a, b) => a - b); // Sort deltas in ascending order

    // using the filter disables rank gaps created by same deltas; for example:
    // when two (2) delta's are the same and get rank 2, next rank is 4, and not 3.
    // valueDeltasSorted = valueDeltasSorted.filter((x, i) => (
    //  i === 0 || (Math.abs(valueDeltasSorted[i] - valueDeltasSorted[i - 1]) > .0001)));

    // Assign rankings based on deltas
    categorySpec.values.forEach((v) => {
      if (protocolTargetAccrual === 0) v["ranking"] = 0;
      else {
        v["ranking"] =
          v.delta < 0.0 // only needy get (positively) ranked, others' ranks are set to 0 and will eventually show blank in ui
            ? 1 +
              valueDeltasSorted.findIndex((x) => Math.abs(x - v.delta) < 0.0001)
            : 0;
      }
    });

    categorySpec.values.forEach((v) => {
      if (protocolTargetAccrual === 0) v["actual"] = 0;
    });

    // Calculate and set total target, actual, and delta for the category
    const totalTarget = targetValues.reduce((x, y) => x + y, 0); // Sum of target values
    categorySpec.totals["target"] = parseFloat(totalTarget.toFixed(5)); // Set total target with 5 decimal places
    categorySpec.totals["actual"] = categorySpec.values
      .map((v) => v["actual"])
      .reduce((x, y) => x + y, 0);
    categorySpec.totals["delta"] = categorySpec.values
      .map((v) => v["delta"])
      .reduce((x, y) => x + y, 0);
  };

  const recalcTotalsInTargetAccruals = (
    targetAccrualsArg,
    categoryInd,
    targetValues,
    protocolTargetAccrual
  ) => {
    const tmp = targetAccrualsArg;
    const categorySpec = tmp.categories[categoryInd];
    recalcTotalsInCategorySpec(
      categorySpec,
      targetValues,
      protocolTargetAccrual
    );
    return tmp;
  };

  const validateValueTarget = (val) => {
    if (val === null || val === undefined) return false;
    const valStr = (val + "").trim();
    return (
      valStr &&
      (valStr.match(/^[0-9]{1,3}\.?[0-9]{0,2}$/) ||
        valStr.match(/^[0-9]{0,3}\.?[0-9]{1,2}$/))
    );
  };

  const sanitizeNum = (numStr) => {
    let sanitized = "";

    const numbers = numStr.split(".");

    // before decimal point
    for (let j = 0; j < 3; j++) {
      const c = numbers[0].charAt(j);
      if (c.match(/[0-9.]/)) {
        sanitized += c;
      }
    }

    if (numbers.length === 1) {
      return sanitized;
    }

    sanitized += ".";

    // after decimal point

    for (let i = 0; i < 2; i++) {
      const c = numbers[1].charAt(i);
      if (c.match(/[0-9.]/)) {
        sanitized += c;
      }
    }

    return sanitized;
  };

  const preValidateValueTarget = (val) => {
    if (val === null || val === undefined) return val;

    const valStr = (val + "").trim();

    if (!valStr) return valStr;

    if (!valStr.match(/^[0-9]{0,3}$/)) {
      if (valStr.includes(".")) {
        if (!valStr.match(/^[0-9]{0,3}\.?[0-9]{0,2}$/)) {
          return sanitizeNum(valStr);
        } else {
          return valStr;
        }
      } else {
        return sanitizeNum(valStr);
      }
    } else {
      return valStr;
    }
  };

  // State for filters
  const filterValues = getItem(
    STORAGE_NAMES.PEP_STUDY_DASHBOARD_FILTERS,
    Object
  );
  const [studyFilters, setStudyFilters] = useState({
    reStatus: filterValues?.reStatus
      ? filterValues?.reStatus
      : StudyDefaults.STUDYDEFAULTSTATUS.join(","),
    suspended: filterValues?.suspended
      ? filterValues?.suspended
      : StudyDefaults.STUDYDEFAULTSUSPENDED,
    honestBrokerStatus: filterValues?.honestBrokerStatus ?? null,
    hbId: filterValues?.hbId ?? null,
    irbNumber: filterValues?.irbNumber ?? null,
    nickname: filterValues?.nickname ?? null,
    protocolNumber: filterValues?.protocolNumber ?? null,
    fuDateFrom: filterValues?.followUpDateFrom || filterValues?.fuDateFrom,
    fuDateTo: filterValues?.followUpDateTo || filterValues?.fuDateTo,
    contactStaffId: filterValues?.contactStaffId ?? null,
    dept: filterValues?.dept ?? null,
    division: filterValues?.division ?? null,
    cancer: filterValues?.cancer || "all",
    source: filterValues?.source === "all" ? null : filterValues?.source,
    ptlStatus: filterValues?.ptlStatus,
    reStartDateFrom:
      filterValues?.recruitmentStartDateFrom || filterValues?.reStartDateFrom,
    reStartDateTo:
      filterValues?.recruitmentStartDateTo || filterValues?.reStartDateTo,
    reEndDateFrom:
      filterValues?.recruitmentEndDateFrom || filterValues?.reEndDateFrom,
    reEndDateTo:
      filterValues?.recruitmentEndDateTo || filterValues?.reEndDateTo,
    protocolSummaryAccrual: filterValues?.protocolSummaryAccrual ?? null,
  });

  // State for Sort
  const [sort, setSort] = useState({
    orderBy: StudyDefaults.STUDYDEFAULTORDERBY,
    order: StudyDefaults.STUDYDEFAULTORDER,
  });

  // State for pagination
  const [pagination, setPagination] = useState({
    page: StudyDefaults.STUDYDEFAULTPAGE,
    rowsPerPage: StudyDefaults.STUDYDEFAULTPAGESIZE,
  });

  useEffect(() => {
    if (honestBrokers?.values.length > 0) {
      setStudyFilters((studyFilters) =>
        studyFilters.hbId === null
          ? {
              ...studyFilters,
              reStatus: filterValues
                ? studyFilters.reStatus
                : StudyDefaults.STUDYDEFAULTSTATUS.join(","),

              suspended: filterValues
                ? studyFilters.suspended
                : StudyDefaults.STUDYDEFAULTSUSPENDED,

              hbId: filterValues
                ? studyFilters.hbId
                : setDefaultHB(honestBrokers?.values, currentUser),
            }
          : studyFilters
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, honestBrokers?.values]);

  /**
   * Calling backend API to fetch studies using filters.
   */
  useEffect(() => {
    if (
      isValidUser(currentUser) &&
      isStudiesLocation(location.pathname) &&
      studyFilters &&
      initialLoad
    ) {
      const getProtocolSummary = (protocolSummaryAccrual) =>
        protocolSummaryAccrual && protocolSummaryAccrual !== "all"
          ? protocolSummaryAccrual === SummaryAccrual.SummaryAccrualTrue
          : null;
      const getCancer = (cancer) =>
        cancer && cancer !== CancerNonCancer.All
          ? cancer === CancerNonCancer.Cancer
          : null;
      const getMultiSite = (protocolMultisiteTrial) =>
        protocolMultisiteTrial && protocolMultisiteTrial !== "all"
          ? protocolMultisiteTrial === MultiSite.MultiSiteTrue
          : null;
      const {
        reStatus,
        suspended,
        hbId,
        irbNumber,
        nickname,
        protocolNumber,
        fuDateFrom,
        fuDateTo,
        contactStaffId,
        dept,
        division,
        honestBrokerStatus,
        cancer,
        protocolMultisiteTrial,
        ptlStatus,
        reStartDateFrom,
        reStartDateTo,
        reEndDateFrom,
        reEndDateTo,
        protocolSummaryAccrual,
      } = studyFilters;
      const { page, rowsPerPage } = pagination;
      const newCancer = getCancer(cancer);
      const newProtocolSummaryAccrual = getProtocolSummary(
        protocolSummaryAccrual
      );
      const newProtocolMultisiteTrial = getMultiSite(protocolMultisiteTrial);
      const newReStartDateFrom =
        reStartDateFrom && formatISO(new Date(reStartDateFrom));
      const newReStartDateTo =
        reStartDateTo && formatISO(new Date(reStartDateTo));
      const newReEndDateFrom =
        reEndDateFrom && formatISO(new Date(reEndDateFrom));
      const newReEndDateTo = reEndDateTo && formatISO(new Date(reEndDateTo));
      const newFuDateFrom = fuDateFrom && formatISO(new Date(fuDateFrom));
      const newFuDateTo = fuDateTo && formatISO(new Date(fuDateTo));

      saveItem(STORAGE_NAMES.PEP_STUDY_DASHBOARD_FILTERS, studyFilters);

      const studiesParams = {
        reStatus,
        suspended,
        hbId,
        irbNumber: irbNumber?.displayText ?? null,
        nickname: nickname?.displayText ?? null,
        protocolNumber: protocolNumber?.displayText ?? null,
        fuDateFrom: newFuDateFrom,
        fuDateTo: newFuDateTo,
        contactStaffId:
          (contactStaffId && contactStaffId.value.staffId) ?? null,
        dept,
        division,
        honestBrokerStatus,
        cancer: newCancer,
        protocolMultisiteTrial: newProtocolMultisiteTrial,
        protocolSummaryAccrual: newProtocolSummaryAccrual,
        ptlStatus,
        reStartDateFrom: newReStartDateFrom,
        reStartDateTo: newReStartDateTo,
        reEndDateFrom: newReEndDateFrom,
        reEndDateTo: newReEndDateTo,
        page,
        size: rowsPerPage,
        sort: `${sort.orderBy},${sort.order}`,
      };

      removeEmptyParams(studiesParams);

      const fetchStudies = async () => {
        try {
          const response = await axios.get(GET_STUDIES_URL_BASE, {
            params: studiesParams,
          });

          setStudies(response.data.values || []);
          setTotalCount(response.data.totalCount);
        } catch (err) {
          // set error.message in AlertContext
          setAlert("error", err.message);
        }
      };

      fetchStudies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentUser,
    location.pathname,
    studyFilters,
    setAlert,
    sort,
    pagination,
    initialLoad,
  ]);

  /**
   * Calling backend API to fetch therapeutic area.
   */
  useEffect(() => {
    (async () => {
      if (isValidUser(currentUser) && location.pathname.includes("studies")) {
        try {
          const response = await axios.get(GET_THERAPEUTICAREAS_URL);
          setTherapeuticAreas(response.data);
        } catch (err) {
          // set error.message in AlertContext
          setAlert("error", err.message);
        }
      }
    })();
  }, [currentUser, location.pathname, setAlert]);

  useEffect(() => {
    (async () => {
      if (isValidUser(currentUser) && location.pathname.includes("studies")) {
        try {
          const response = await axios.get(GET_BENCHMARKS_URL);
          setBenchmarks(response.data);
        } catch (err) {
          // set error.message in AlertContext
          setAlert("error", err.message);
        }
      }
    })();
  }, [currentUser, location.pathname, setAlert]);

  /**
   * Calling backend API to fetch funding sources.
   */
  useEffect(() => {
    (async () => {
      if (isValidUser(currentUser) && location.pathname.includes("studies")) {
        try {
          const response = await axios.get(GET_FUNDINGSOURCES_URL_BASE);
          setFundingSources(response.data);
        } catch (err) {
          // set error.message in AlertContext
          setAlert("error", err.message);
        }
      }
    })();
  }, [currentUser, location.pathname, setAlert]);

  useEffect(() => {
    (async () => {
      if (isValidUser(currentUser) && location.pathname.includes("studies")) {
        try {
          const response = await axios.get(GET_ALL_HBUSERS_URL);
          setAllHonestBrokers(response.data);
        } catch (err) {
          // set error.message in AlertContext
          setAlert("error", err.message);
        }
      }
    })();
  }, [currentUser, location.pathname, setAlert]);

  return (
    <StudiesContext.Provider
      value={{
        studies,
        setStudies,
        studyFilters,
        setStudyFilters,
        therapeuticAreas,
        fundingSources,
        benchmarks,
        totalCount,
        setTotalCount,
        sort,
        setSort,
        pagination,
        setPagination,
        recalcTotalsInTargetAccruals,
        validateValueTarget,
        preValidateValueTarget,
        allHonestBrokers,
      }}
    >
      {children}
    </StudiesContext.Provider>
  );
};

export const useStudies = () => {
  return useContext(StudiesContext);
};

export const getOutreachFailureSummary = async (
  studyId,
  setIRBNumber,
  setOutreachFailureSummaryReport,
  setAlert
) => {
  try {
    const request = {
      url: `${GET_STUDIES_URL_BASE}/${studyId}${URLs.GET_STUDY_LISTING_ERROR_SUMMARY}`,
    };

    const response = await axios(request);
    setIRBNumber(response.data.irbNumber || "");
    setOutreachFailureSummaryReport(response.data.report || []);
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const clearOutreachFailureSummary = async (
  studyId,
  setOutreachFailureSummaryReport,
  setAlert,
  studies,
  setStudies,
  setOutreachRefresh,
  outreachRefresh,
  history
) => {
  try {
    const request = {
      method: "PUT",
      url: `${GET_STUDIES_URL_BASE}/${studyId}/clearAlerts`,
    };

    const response = await axios(request);

    let dataIndex = studies.findIndex((studyObj) => studyObj.id === studyId);
    let newStudies = [...studies];
    newStudies[dataIndex] = response.data;
    setStudies(newStudies);
    setOutreachFailureSummaryReport([]);
    const location = {
      state: null,
    };
    history && history.replace(location);
    setOutreachRefresh && setOutreachRefresh(!outreachRefresh);
  } catch (error) {
    setAlert("error", error.message);
  }
};
